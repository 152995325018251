

@import 'main.css';
@import 'wrapper.css';
@import 'font.css';

*, *::before, *::after {
  font-family: "MontserratRegular", Gotham Rounded, sans-serif;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "MontserratMedium", Gotham Rounded, sans-serif;
}

html {
  font-size: 25px;
  overflow-x: hidden!important;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden!important;
}

/* .col-12 {
  padding-left: 0!important;
  padding-right: 0!important;
} */

.btn {
  box-shadow: none!important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #000;
  border-color: #000;
  box-shadow: none!important;
}

.btn-primary {
  color: #fff;
  background-color: transparent;
  border-color: #6c757d;
  border: 2px solid #FFF;
  border-radius: 50px;
  width: 200px;
  margin: 0 auto;
  font-weight: bold;
  letter-spacing: 2px;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
  box-shadow: none!important;
}

.mr-1 {
  margin-right: 5px;
}

.navbar {
  z-index: 2;
  background: #2c355e;
  background: #0b3954ff;
  height: 90px;
}

.navbar > div {
  color: #FFF;
}

.nav-link {
  color: #FFF;
}

.nav-link:hover {
  color: gold;
}

.section-title {
  height: 200px;
}

.section-title h1 {
  font-size: 2rem;
  color: #0b3954ff;
  border-bottom: 3px solid #000;
  width: 200px;
  margin: 0 auto;
}

/* #banner {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
} */

#banner .banner__block > div {
  max-width: 1340px;
  height: 600px;
  margin: 0 auto;
  justify-content: space-around;
}

#banner .banner__block {
  background-image: url('../images/background_wood_banner_v4.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

#banner .banner_card {
  background-color: rgba(255, 90, 95, 1);
  color: #FFF;
  padding: 2rem 1.25rem;
}

#banner .personal-pic img {
  border-radius: 50%;
  background-color: rgb(255,255,255, .85);
  max-width: 350px;
}

#contact {
  padding: 75px 0;
}

#contact > div:first-of-type {
  color: #0b3954ff;
  margin: 0 auto;
  border-bottom: 3px solid #087e8bff;
}

.contact__icons {
  justify-content: space-evenly;
  margin: 25px auto 0;
}

.contact__icons {
  font-size: 75px;
}

.contact__icons > div:first-of-type, .contact__icons > div:first-of-type a, .contact__icons > div:first-of-type a:hover {
  color: #010D47;
}

.contact__icons > div:nth-of-type(2), .contact__icons > div:nth-of-type(2) a, .contact__icons > div:nth-of-type(2) a:hover {
  color: #087e8bff;;
}

.contact__icons > div:nth-of-type(3), .contact__icons > div:nth-of-type(3) a, .contact__icons > div:nth-of-type(3) a:hover {
  color: darkgoldenrod;
}

.contact__icons > div:last-of-type, .contact__icons > div:last-of-type a, .contact__icons > div:last-of-type a:hover {
  color: #000;
}

.contact__icons > div:last-of-type > div {
  font-size: 0.85rem;
  margin-top: -15px;
}

#summary {
  background: #ff5a5fff;
  padding: 5rem 0;
}

#summary .summary__container > div {
  max-width: 700px;
  margin: 0 auto;
}

.summary__border {
  height: 25px;
  /* border: 5px solid #010D47; */
  border-bottom: none;
}

.left-radius {
  border-top-left-radius: 25px;
  border-right: none;
}

.center-radius {
  height: 34px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin: -5px auto;
  text-align: center;
  padding-top: 3px;
  z-index: 1;
  background: #FFF;
}

.right-radius {
  border-left: none;
  border-top-right-radius: 25px;
}

#summary {
  clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 calc(100% - 100px));
}

#summary > div:first-of-type {
    margin: 0 auto;
    color: #FFF;
    border-bottom: 3px solid #0b3954ff;
}

#summary > div:nth-of-type(2) {
  padding: 100px 0;
}

#summary .summary__flex {
  background: #FFF;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

#summary .summary__block {
  /* border: 5px solid #010D47; */
  border-top: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 400px;
  background: #FFF;
}

.summary__title {
  margin: 0 1.5rem;
}

.summary__title h1 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0b3954ff;
  text-align: center;
  padding-top: 30px;
}

.summary__title h2 {
  font-size: 1rem;
  font-weight: bold;
  color: #c81d25ff;
  text-align: center;
}

.summary__title p {
  font-size: 1rem;
  color: gray;
  text-align: center;
  padding-top: 20px;
}

.summary__icon {
  font-size: 2.5rem;
  color: #0b3954ff;
}

.summary__icon svg {
  margin-bottom: 10px;
}

#portfolio {
  background: #f6f6f6;
  margin-top: -100px;
}

#portfolio .row {
  margin-bottom: 2rem;
}

#portfolio .title {
  color: #0b3954ff;
  margin: 50px auto;
  border-bottom: 3px solid #087e8bff;
  padding-top: 50px;
}

#portfolio > div:nth-of-type(2) {
  margin: 0 auto;
}

#portfolio .portfolio_block_container {
  height: 450px;
  width: 100%;
  overflow: hidden;
}

#portfolio .portfolio__block {
  height: 100%;
  width: 100%;
  transition: all 2s;
}

#portfolio .portfolio__block_description {
  height: 450px;
}

#portfolio .row:first-of-type > div:last-of-type > div {
  background-color: #0057b8;
  color: #FFF;
}

#portfolio .row:first-of-type > div:last-of-type > div > div {
  background-color: #FFF;
  color: #0057b8;
  margin: 0 -5px;
  height: 300px;
}

#portfolio .row:first-of-type > div:last-of-type > div > div h4, #portfolio .row:nth-of-type(2) > div:last-of-type > div > div h4, #portfolio .row:nth-of-type(3) > div:last-of-type > div > div h4 {
  color: #484848;
  border-bottom: 3px solid #ff5a5fff;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 5px;
}

#portfolio .row:first-of-type > div:last-of-type > div > div p, #portfolio .row:nth-of-type(2) > div:last-of-type > div > div p, #portfolio .row:nth-of-type(3) > div:last-of-type > div > div p {
  margin: 25px 15px 0;
  color: gray;
}



#portfolio .row:nth-of-type(2) > div:last-of-type > div {
  background-color: #4a0d66;
  color: #FFF;
}

#portfolio .row:nth-of-type(2) > div:last-of-type > div > div {
  background-color: #FFF;
  color: #4a0d66;
  margin: 0 -5px;
  height: 300px;
}

#portfolio .row:nth-of-type(3) > div:last-of-type > div {
  background-color: #003086;
  color: #FFF;
}

#portfolio .row:nth-of-type(3) > div:last-of-type > div > div {
  background-color: #FFF;
  color: #003086;
  margin: 0 -5px;
  height: 300px;
}

#portfolio .row:first-of-type > div:first-of-type > div:first-of-type > div {
  background-image: url('../images/portfolio_make-a-wish.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#portfolio .row:nth-of-type(2) > div:first-of-type > div:first-of-type > div {
  background-image: url('../images/portfolio_ride_end_alz.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#portfolio .row:nth-of-type(3)> div:first-of-type > div:first-of-type > div {
  background-image: url('../images/portfolio_beyond_boston.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#portfolio .portfolio_block_container:hover .portfolio__block,
#portfolio .portfolio_block_container:focus .portfolio__block {
  transform: scale(1.1);
}

/* #portfolio .row > div:first-of-type > div:hover {
  background: rgba(0,0,0,0.8)
} */

#portfolio .row:nth-of-type(2) {
  flex-direction: row-reverse;
}

.portfolio__block a {
  text-decoration: none;
}

#space {
  height: 175px;
  background: #f6f6f6;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
}

.mission {
  background: rgba(0,0,0,0);
  color: transparent;
  width: 100%;
  height: 100%;
}

.mission:hover {
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  color: #FFF;
}

.mission a {
  display: none;
}

.mission:hover a {
  display: block;
}

.mission p {
  font-size: 1.15rem;
  text-decoration: none;
}

#clients .clients__container {
  margin: 0 auto;
}

#clients .title {
  color: #0b3954ff;
  margin: 0 auto;
  border-bottom: 3px solid #087e8bff;;
}

#clients .clients__block {
  height: 175px;
}

#clients .top a {
  text-align: center;
}

#clients .top svg {
  font-size: 1.65rem;
  margin-bottom: 0.5rem;
  color: #ff5a5fff;
}

#clients .top div {
  color: #0b3954ff;
}

#footer {
  background: #0b3954ff;;
}

#footer div {
  height: 200px;
  color: #FFF;
  align-items: center;
}

.fade {
  opacity: 0;
  transition: 0.4s opacity;
}

@media only screen and (min-width: 768px) {
  .navbar-nav li:first-of-type {
    display: none;
  }
}

@media only screen and (max-width: 1515px) {
  #portfolio div.portfolio_container:last-of-type h2 {
    font-size: 1.5rem;
  }
  .summary__title p {
    padding-top: 0;
    font-size: 0.90rem;
  }
}

@media only screen and (max-width: 1199px) {

  #summary .summary__container > div:first-of-type {
    padding-bottom: 1rem;
  }

  #portfolio .portfolio_container {
    flex-direction: column-reverse;
  }
  #portfolio .row:nth-of-type(2) {
    flex-direction: column-reverse!important;
  }
  #portfolio div.portfolio_container:last-of-type h2 {
    font-size: 2rem;
  }
  #summary .summary__container {
    flex-direction: column;
    height: 100%;
  }
}

@media only screen and (max-width: 1010px) {

  #summary .summary__container > div:first-of-type {
    padding-bottom: 0;
  }

  #banner .banner__block > div {
    height: auto;
  }

  #banner .banner__block {
    flex-direction: row;
    height: auto;
    padding-top: 2rem;
  }

  #banner .banner_card {
    height: 100%;
    padding: 15px 5px;
    max-width: 400px;
  }

  #banner .personal-pic {
    justify-content: center!important;
    margin: 0 auto!important;
    padding: 1rem;
  }
  #banner .personal-pic img {
    width: 90%;
    height: 90%;
  }

  #banner .banner_card h2 {
    font-size: 1.75rem;
  }

  #contact {
    padding: 50px 0;
  }

  #contact h3 {
    margin: 10px auto;
  }

  #contact .contact__icons {
    padding: 0;
  }

  #contact .contact__icons svg {
    width: 60px;
  }

  .contact__icons > div:last-of-type > div {
    font-size: 0.6rem;
  }

  #summary {
    clip-path: none;
    padding: 2rem 0;
  }

  #summary h3 {
    margin: 10px auto;
  }

  #summary > div:nth-of-type(2) {
    padding: 60px 0 25px;
  }

  #summary .summary__container {
    flex-direction: column;
    height: 100%;
  }

  #summary .summary__container > div:last-of-type {
    padding-bottom: 50px;
  }

  #summary .summary__container > div > div {
    margin: 0!important;
  }

  #summary .summary__title {
    margin: 0;
  }
  #summary .summary__block {
    height: 100%;
    padding-bottom: 10px;
  }

  #summary .row div:nth-of-type(2) {
    padding-top: 35px;
  }

  #space {
    display: none;
  }

  #portfolio .portfolio_container {
    flex-direction: column-reverse;
  }

  #portfolio .portfolio_container > div {
    max-width: 700px;
    margin: 0 auto;
  }

  #portfolio .row:nth-of-type(2) {
    flex-direction: column-reverse!important;
  }

  #portfolio .row:first-of-type > div:first-of-type > div:first-of-type > div {
    background-image: url('../images/portfolio_make-a-wish_mobile.png');
  }

  #portfolio .portfolio_block_container {
    height: 350px;
  }

  #portfolio .portfolio__block_description {
    height: auto;
  }

  #portfolio .portfolio__block_description h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  #portfolio .portfolio__block_description h2 {
    font-size: 1.75rem;
    margin: 1rem 0;
  }
  #portfolio .portfolio__block_description p {
    text-align: center;
  }

  #portfolio .title {
    margin: 0px auto 25px;
    padding: 0;
    text-align: center;
  }
  #portfolio .title h3 {
    margin: 10px auto;
  }


  #portfolio .portfolio__block_more_description {
    height: 100%!important;
  }

  #portfolio .portfolio_container:first-of-type .portfolio__block_more_description {
    border-bottom: solid 10px #0057b8;
  }

  #portfolio .portfolio_container:nth-of-type(2) .portfolio__block_more_description {
    border-bottom: solid 10px #4a0d66;
  }

  #portfolio .portfolio_container:last-of-type .portfolio__block_more_description {
    border-bottom: solid 10px #003086;
  }


  #portfolio .row:first-of-type > div:last-of-type > div > div h4, #portfolio .row:nth-of-type(2) > div:last-of-type > div > div h4, #portfolio .row:nth-of-type(3) > div:last-of-type > div > div h4 {
    margin-top: 1rem;
  }
  
  #portfolio .row:first-of-type > div:last-of-type > div > div p, #portfolio .row:nth-of-type(2) > div:last-of-type > div > div p, #portfolio .row:nth-of-type(3) > div:last-of-type > div > div p {
    margin: 25px 15px 1rem;
    color: gray;
  }

  #clients .clients__container > div {
    margin: 0!important;
  }

  #clients .title h3 {
    margin: 10px auto;
  }

  #clients .clients__block {
    height: 125px;
  }

  .clients__container.first_row > div:first-of-type img {
    max-height: 85px;
  }

  .clients__container.first_row > div:nth-of-type(2) img {
    max-height: 75px;
  }

  .clients__container.first_row > div:nth-of-type(3) img {
    max-height: 125px;
  }

  .clients__container.first_row > div:nth-of-type(4) img {
    max-height: 70px;
  }

  .clients__container.first_row > div:nth-of-type(5) img {
    max-height: 53px;
  }

  .clients__container.first_row > div:nth-of-type(6) img {
    max-height: 85px;
  }

  .clients__container.first_row > div:nth-of-type(7) img {
    max-height: 55px;
  }

  .clients__container.first_row > div:nth-of-type(8) img {
    max-height: 52px;
  }

  .clients__container.first_row > div:nth-of-type(9) img {
    max-height: 54px;
  }

  .clients__container.first_row > div:nth-of-type(10) img {
    max-height: 42px;
  }

  .clients__container.first_row > div:nth-of-type(11) img {
    max-height: 70px;
  }

  .clients__container.first_row > div:last-of-type img {
    max-height: 77px;
  }

  #footer div {
    padding: 0 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav .nav-link {
    border-bottom: 3px solid darkgoldenrod;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-nav.no-callout {
    padding-top: 4rem;
  }

  .navbar-nav .nav-link {
    padding: 0.25rem 0.15rem;
    margin-right: 5rem;
    font-size: 1.25rem!important;
  }

  .offcanvas-collapse.show .navbar-nav li {
    margin: 0.35rem 4%;
  }
  #banner .banner__block > div {
    flex-direction: column;
    height: auto;
  }
  #banner .banner_card {
    padding: 0 0.75rem;
    max-width: 400px;
  }
}
